import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Calendly from '../components/Calendly'

function PageTemplate(props) {
  const [language, setLanguage] = useState('0')
  const [nodeLocale, setNodeLocale] = useState('en-US')
  const [popupCalendly, setPopupCalendly] = useState(false)

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        setLanguage(localStorage.getItem('language'))
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language')) === '1') {
        setLanguage(1)
        setNodeLocale('fr')
      } else {
        setLanguage(0)
        setNodeLocale('en-US')
      }
    }
  }, [nodeLocale])

  const getLanguage = data => {
    localStorage.setItem('language', data);
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  const data = props.data
  const { title, slug, body } = data.contentfulPage
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.contentfulPage

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language}/>
      <Layout data={data}>
        <SEO pagePath={slug} postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-page">
          <main className="page">
            <section className="section-1">
              <div className="container">
                <h1 className="headline">{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}/>
              </div>
            </section>
            <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
          </main>
        </div>
      </Layout>
      <Footer getLanguage={getLanguage}/>
    </div>
  )
}

export default PageTemplate

export const query = graphql`
  query($slug: String!) {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`
